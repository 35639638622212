body {
  background: #00252b;
  color: white;
  margin-top: 100px;
  margin-bottom: 100px;
}

.peepoHeader {
  width: 100%;
  text-align: center;
}

.peepoSearch,
.peepoSelect {
  background: #002b31;
  border-radius: 5px;
  border-top: 2px solid rgb(0, 132, 0);
  border-left: 2px solid rgb(0, 132, 0);
  border-right: 2px solid rgb(0, 63, 0);
  border-bottom: 2px solid rgb(0, 63, 0);
  color: white;
  height: 20px;
  width: 350px;
  padding: 0 4px 0 4px;
  transition: border 200ms;
  max-width: 60%;
  margin-top: 50px;
}

.peepoSelect {
  width: unset;
  height: 24px;
  margin-left: 2px;
}

.peepoSearch:focus,
.peepoSelect:focus {
  outline: none;
  border-top: 2px solid rgb(0, 63, 0);
  border-left: 2px solid rgb(0, 63, 0);
  border-right: 2px solid rgb(0, 132, 0);
  border-bottom: 2px solid rgb(0, 132, 0);
}

.loading,
.error {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.loadingImg {
  width: 100px;
  animation: rotation 1s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.peepoMain {
  margin: auto;
  margin-top: 100px;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  row-gap: 100px;
  justify-content: center;
}

.peepoDiv {
  display: flex;
  justify-content: flex-end;
  flex-basis: 340px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.peepoContainer {
  margin-bottom: -2px;
}

.peepoImg {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  max-height: 240px;
  border-radius: 5px;
  transition: transform 0.05s, transform-origin 0.025s;
  transform-origin: bottom;
}

.peepoImg:hover {
  transform: scale(1.05);
}

.peepoTag {
  border-radius: 4px;
  width: calc(100% - 12px);
  height: 67px;
  border-top: 2px solid rgb(0, 132, 0);
  border-left: 2px solid rgb(0, 132, 0);
  border-right: 2px solid rgb(0, 63, 0);
  border-bottom: 2px solid rgb(0, 63, 0);
  padding-left: 6px;
  padding-right: 6px;
  background: #002b31;
  text-align: center;
  overflow: auto;
}

.peepoTag::-webkit-scrollbar {
  width: 8px;
}

.peepoTag::-webkit-scrollbar-track {
  background: #00252b
}

.peepoTag::-webkit-scrollbar-thumb {
  background: solid rgb(0, 63, 0);
}

.peepoTag::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 132, 0);
}

.peepoZoom {
  transform: scale(2.5);
  transform-origin: bottom;
  z-index: 2;
  position: relative;
  -webkit-touch-callout: none;
}

.overleft {
  transform-origin: calc(35%) 100%;
}

.overright {
  transform-origin: calc(65%) 100%;
}

.peepoZoom:hover {
  transform: scale(2.5);
}

.infinityScroll {
  position: absolute;
  top: 8px;
  left: 8px;
}

.copyInfo {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2px;
  background: #00252bde;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms, visibility 500ms;
}

.copyInfoVisible {
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms, visibility 500ms;
}

@media (max-width: 721px) {
  body {
    margin-top: 70px;
  }

  .peepoTitle {
    max-height: 20vh;
  }

  .peepoHeader {
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .peepoSearch,
  .peepoSelect {
    margin-top: 25px;

  }

  .peepoMain {
    width: 95%;
    gap: 20px;
    margin-top: 60px;

  }

  .peepoDiv {
    flex-basis: 30%;
  }

  .peepoImg {
    max-height: 80px;
  }

  .peepoTag {
    font-size: 12px;
    word-break: break-all;
    padding-left: 2px;
    padding-right: 2px;
    width: calc(100% - 4px)
  }

  .copyInfo {
    font-size: 12px;
  }

  .overleft {
    transform-origin: calc(6%) 100%;
  }

  .overright {
    transform-origin: calc(94%) 100%;
  }

}

@media (max-width: 376px) {
  .peepoTitle {
    max-height: 18vh;
  }

  .peepoDiv {
    flex-basis: 25%;
  }
}